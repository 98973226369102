<template>
  <v-container>
    <v-row>
      <v-col>
        <h5 class="grey-text">
          <router-link class="grey-text" :to="{ name: 'Home' }">
            Home</router-link
          >
          >
          <router-link :to="{ name: 'Blog' }">Blog</router-link>
          > Scientist vs Caveman Hitting Approach
        </h5>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col cols="12">
        <v-img :src="Scientist2VsCaveman2" contain height="300" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3 class="mt-2">Scientist vs Caveman Hitting Approach</h3>
        <h5 class="grey-text">April 10th, 2022 - Stone Osborne</h5>
      </v-col>
      <v-col>
        <p>
          I like to define the hitting appproach as 2 different styles, a
          scientist's approach and caveman's approach. Both will have their
          advantages and disadvantages. So let's jump in and discuss their
          characteristics and when to use them.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>Scientist's Approach</h3>

        <p class="mt-2">
          With this approach we ask our hitters to slow down and to be a little
          more methodical with their actions. This approach is best used in:
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <p>
          <strong> Low-Medium Stress Environments:</strong>
        </p>
        <ul>
          <li>Tee-Work</li>
          <li>Flip Variations</li>
          <li>Batting Practice</li>
        </ul>

        <p class="mt-1">
          This approach is great <strong> early on </strong> in our day to day
          training, and game day preparation.
        </p>
      </v-col>

      <v-col cols="12" md="6">
        <p><strong>Characteristics We Encourage Are:</strong></p>
        <ol>
          <li>Fail Forward</li>
          <li>Have Experimental Mindset</li>
          <li>Thought Driven Actions (Internal Cues)</li>
        </ol>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <h3 class="mt-4">Fail Forward</h3>
        <p class="mt-2">
          Early on in tee work, flip variations, and batting practice you are
          not expected to drive every baseball out of the park. You are probably
          going to "FAIL" or miss hit some baseballs.
        </p>
        <p>Allow Yourself To "Fail Early On".</p>
      </v-col>
      <v-col cols="12" md="4">
        <h3 class="mt-4">Experimental Mindset</h3>

        <p class="mt-2">
          Some days you will find something in your swing is "a little off". It
          could be you didn't sleep well or still sore from last game/lift.
          Don't be afraid to make an adjustment.
        </p>
        <p>Be "Experimental Within Reason".</p>
      </v-col>

      <v-col cols="12" md="4">
        <h3 class="mt-4">Thought Driven Actions (Internal Cues)</h3>

        <p class="mt-2">Create specific swing thoughts/feels. Examples:</p>
        <ul>
          <li>Stay Inside The Baseball</li>
          <li>Palm up, Palm Down</li>
          <li>Challenge Your Timing</li>
        </ul>
        <p class="mt-1">"Find Your Feels Kid".</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>Caveman's Approach</h3>

        <p class="mt-2">
          With this approach we ask our hitters to be reactionary and "let the
          swing eat". This approach is best used in:
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <p>
          <strong> Medium-High Stress Environments:</strong>
        </p>
        <ul>
          <li>Machine Batting Practice</li>
          <li>Competive Batting Practice</li>
          <li>Game/Live Arm in Practice</li>
        </ul>

        <p class="mt-1">
          This approach is great <strong>later on</strong> in our day to day
          training, and game day preparation.
        </p>
      </v-col>

      <v-col cols="12" md="6">
        <p><strong>Characteristics We Encourage Are:</strong></p>
        <ol>
          <li>Strive For Success</li>
          <li>Instinctual Mindset</li>
          <li>Result Driven (External Cues)</li>
        </ol>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <h3 class="mt-4">Strive For Success</h3>
        <p class="mt-2">
          Do not let failure creep into the back of your mind. Believe in the
          countless hours of work you have put last offseason and practices. Now
          go compete.
        </p>
        <p>Remind Yourself "I Will Be Successful".</p>
      </v-col>
      <v-col cols="12" md="4">
        <h3 class="mt-4">Instinctual Mindset</h3>

        <p class="mt-2">
          Trust your offseason training and prep work because when the game
          speeds up we can no longer "think and hit". Switch gears and become a
          competitive beast.
        </p>
        <p>Allow Your "Instincts To Take Over"</p>
      </v-col>

      <v-col cols="12" md="4">
        <h3 class="mt-4">Result Driven (External Cues)</h3>

        <p class="mt-2">Be reactionary. Examples:</p>
        <ul>
          <li>See Ball, Hit Ball</li>
          <li>Hit Ball Hard</li>
          <li>Burn The Center Fielder</li>
        </ul>
        <p class="mt-1">"Keep The Game Simple".</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>Summary</h3>

        <p class="mt-2">
          I like to challenge our hitters early on in the training day or
          pregame prep to be methodical and chase certain "feels". During this
          time the speed of the game is moving pretty slow. So we can experiment
          and work on improving specific swing flaws.
        </p>

        <p>
          Once the speed of the game increases, such as competitive batting
          practice or actual game reps, we ask our hitters to be more
          instinctual and reactionary. As Yogi Berra said "You can't think and
          hit at the same time".
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Scientist2VsCaveman2 from '@/assets/images/Scientist2VsCaveman2.svg'
export default {
  data() {
    return {
      Scientist2VsCaveman2: Scientist2VsCaveman2,
    }
  },
}
</script>

<style>
.v-application a {
  color: grey;
}
</style>
